<template>
  <div>
    <v-container fluid>

      <Lightbox ref="lightbox"/>

      <v-row>
        <v-col cols="12">
          <GiftyTabs/>
        </v-col>
        <v-col cols="12">
          <v-card class="shadow rounded-lg">

            <v-row align="center" class="pa-0 ma-0 py-1 mx-1">
              <v-col cols="12" lg="3">
                <v-text-field v-model.trim="keyword"
                              class="rounded-input"
                              clearable
                              dense
                              hide-details
                              outlined
                              placeholder="Recherche..." prepend-inner-icon="mdi-magnify"
                              single-line/>
              </v-col>
              <v-col cols="12" lg="9" class="text-end">
                <v-btn v-if="Object.keys(filter).length"
                       color="red"
                       text @click="[filter = {},getLiaisons()]">
                  <v-icon left>mdi-filter-off</v-icon>
                  Filtre
                </v-btn>

                <v-btn text
                       color="primary"
                       @click="$refs.filterDatabaseDialog.open()">
                  <v-icon left>mdi-filter</v-icon>
                  Filtre
                </v-btn>

                <v-btn color="primary" text @click="$func.export('gifty/export-excel-liaisons')">
                  <v-icon left>mdi-microsoft-excel</v-icon> Expoter
                </v-btn>

                <v-btn color="primary" icon @click="getLiaisons()">
                  <v-icon>mdi-table-refresh</v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <v-divider/>

            <v-card-text>

              <v-skeleton-loader v-if="isLoading" type="table"/>

              <div v-else>
                <div v-if="salepoints.length">
                  <v-simple-table class="table-border rounded-lg">
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th>Nom</th>
                        <th>Code</th>
                        <th>Magasin</th>
                        <th>N°Téléphone</th>
                        <th>Type</th>
                        <th>Date</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(item,index) in salepoints" :key="index">
                        <td>
                          <div class="d-flex text-no-wrap align-center">

                            <v-avatar v-if="item.salepoint.image" size="38">
                              <v-img :src="$baseUrl + item.salepoint.image"
                                     class="zoom-pointer"
                                     @click="$refs.lightbox.open(item.salepoint.image)"></v-img>
                            </v-avatar>

                            <v-avatar v-else
                                      :class="$func.randomColor()"
                                      class="font-weight-medium"
                                      size="38">
                              {{ $func.avatarName(item.salepoint.name) }}
                            </v-avatar>

                            <span class="ml-2">{{ item.salepoint.name }}</span>
                          </div>
                        </td>
                        <td>
                          <span class="font-weight-medium orange--text">{{ item.salepoint.code }}</span>
                        </td>
                        <td>{{ item.salepoint.store_name }}</td>
                        <td>
                          {{ item.salepoint.phone }}
                        </td>
                        <td>{{ item.salepoint.type }}</td>
                        <td>
                          {{ item.created_at }}
                        </td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <v-card-actions v-if="total > 10">

                    <div v-if="!$vuetify.breakpoint.mobile"
                         class="d-flex align-center">
                      <v-select v-model="per_page"
                                :items="[10,20,50,100,200,300,400,500]"
                                :style="{width: '120px'}"
                                dense
                                hide-details
                                label="Ligne par page"
                                outlined></v-select>
                      &nbsp;
                      {{ salepoints.length }} sur {{ total }}
                    </div>

                    <v-spacer/>
                    <v-pagination v-if="total>10"
                                  v-model="pagination.current"
                                  :length="pagination.total"

                                  total-visible="6"
                                  @input="onPageChange"
                    ></v-pagination>
                  </v-card-actions>

                </div>
                <NoResult v-else/>
              </div>

            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

    </v-container>

    <FilterDatabaseDialog ref="filterDatabaseDialog"
                          :has-dates="true"
                          @search="[pagination.current = 1,filter = $event,getLiaisons()]"/>

  </div>
</template>

<script>
import GiftyTabs from "@/views/supervisor-view/gifty/components/GiftyTabs.vue";
import FilterDatabaseDialog from "@/views/database/components/FilterDatabaseDialog.vue";
import {HTTP} from "@/http-common";

export default {
    components: {FilterDatabaseDialog, GiftyTabs},
    data() {
        return {
            overlay: false,
            salepoints: [],

            isLoading: false,

            keyword: null,
            total: 0,
            per_page: 10,
            pagination: {
                current: 1,
                total: 0
            },
            filter: {}
        }
    },
    methods: {
        getLiaisons() {
            this.isLoading = true
            HTTP.get('/gifty/liaisons?page=' + this.pagination.current, {
                params: {
                    keyword: this.keyword,
                    per_page: this.per_page,
                    filter: this.filter
                }
            }).then((res) => {
                this.isLoading = false
                this.salepoints = res.data.data.data

                this.pagination.current = res.data.data.current_page
                this.pagination.total = res.data.data.last_page
                this.total = res.data.data.total

            }).catch(err => {
                this.isLoading = false
                console.log(err)
            })
        },
        onPageChange() {
            this.getLiaisons();
        },
    },
    created() {
        this.getLiaisons()
    },
    watch: {
        keyword() {
            this.getLiaisons();
        },
        per_page() {
            this.pagination.current = 1
            this.getLiaisons();
        },
    },
}
</script>

<style scoped>

</style>